@mixin resize-img-wrapper {
  position: relative;
  width: 100%;

  &::after {
    content: "";
    display: block;
    @content;
  }
}

@mixin animation {
  transition: all ease-out 0.3s;
}

@mixin hover-link {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: background-color ease 0.3s;
  }

  &:hover {
    &::after {
      background-color: $main-white;
    }
  }
}

@keyframes animation-circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin run-circle {
  animation: 10s linear 1s infinite animation-circle;
}

$media-list: (
  "mobile-350": 350,
  "mobile-450": 450,
  "mobile-620": 620,
  "mobile": 768,
  "tablet-1000": 1000,
  "tablet-1200": 1200,
  "tablet": 1559,
);

@function _get-media-width($width, $maxmin) {
  @if map-get($media-list, $width) {
    $width: map-get($media-list, $width);

    @if $maxmin {
      @return $width;
    } @else {
      @return $width + 1;
    }
  } @else {
    @return $width;
  }
}

@mixin media($width, $maxmin: true) {
  $width: _get-media-width($width, $maxmin);

  @if ($maxmin) {
    @media only screen and (max-width: $width + "px") {
      @content;
    }
  } @else {
    @media only screen and (min-width: $width + "px") {
      @content;
    }
  }
}

@mixin resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

$resolutiond: 1920;

@function vwd($px) {
  @return (($px/$resolutiond) * 100) + vw;
}

@function vwx($px) {
  @return (($px/$resolutiond) * 80) + vw;
}

@function novwx($px) {
  @return ($px) + px;
}

$baseHeight: 1080;

@function toVh($px) {
  @return (($px/$baseHeight) * 100) + vh;
}
