.nav-widget {
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrapper {
        position: fixed;
        bottom: 50px;
        right: 70px;
        z-index: 100;
        width: 350px;

        @include media('tablet-1000', true) {
            display: none;
        }
    }

    &__line {
        position: relative;
        background-color: #484848;
        max-width: 273px;
        width: 100%;
        margin-right: 17px;
        height: 2px;

        &-active {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #99E836;
            height: 100%;
            transition: width .3s ease;
        }
    }

    &__page-number {
        color: #fff;
    }

}