.custom-cursor {
    position: fixed;
    z-index: 100;
    margin: -0.5em 0 0 -0.5em;
    width: 1em;
    height: 1em;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    font-size: 270px;
    transition: opacity 0.3s, visibility 0.3s;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include media('tablet', true) {
        font-size: 180px;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }


}