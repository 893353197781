.blog {
    &__container {
        position: relative;
    }

    &__row {}

    &__title {
        margin-bottom: 60px;

        @include media("tablet-1000", true) {
            margin-bottom: 50px;
        }

        @include media("mobile", true) {
            margin-bottom: 40px;
        }
    }

    &__row {}

    &__swiper-container {
        margin-right: -70px;
        overflow: hidden;
        cursor: url("../img/cursor-slider.png") 62 62, auto;

        @include media("tablet-1200", true) {
            margin-right: -45px;
        }

        @include media("mobile-620", true) {
            margin-right: -10px;
        }
    }

    &__swiper-wrapper {}

    &__swiper-slide {
        &-sm {}

        &-xl {
            @include media("tablet-1000", true) {
                display: none;
            }
        }
    }

    &__card {
        display: block;
        &-md {
            max-width: 688px;
        }

        &-sm {
            max-width: 324px;

            @include media("mobile", true) {
                max-width: 300px;
            }

            @include media("mobile-450", true) {
                max-width: 200px;
            }
        }
    }

    &__card-img {
        margin-bottom: 40px;
        height: 470px;

        @include media("tablet-1000", true) {
            margin-bottom: 30px;
        }

        @include media("mobile", true) {
            max-width: 300px;
            margin-bottom: 20px;
        }

        @include media("mobile-450", true) {
            max-width: 200px;
            height: 300px;
        }
    }

    &__card-date {
        margin-bottom: 20px;
        color: $light-grey;

        @include media("tablet-1000", true) {
            margin-bottom: 10px;
        }

        @include media("mobile") {
            font-size: 14px;
        }

        @include media("mobile-450") {
            font-size: 12px;
        }
    }

    &__card-title {
        text-transform: none;

        @include media("mobile") {
            font-size: 24px;
        }

        @include media("mobile-450") {
            font-size: 18px;
        }
    }
}

.swiper-button-prev2 {
    margin-right: 10px;
    cursor: pointer;

    svg {
        circle {
            transition: stroke .3s ease;
        }

        path {
            transition: stroke .3s ease;
        }
    }

    &:hover {
        svg {
            circle {
                stroke: #FFFCF9;
            }

            path {
                stroke: #FFFCF9;
            }
        }
    }
}

.swiper-button-next2 {
    cursor: pointer;

    svg {
        circle {
            transition: stroke .3s ease;
        }

        path {
            transition: stroke .3s ease;
        }
    }

    &:hover {
        svg {
            circle {
                stroke: #FFFCF9;
            }

            path {
                stroke: #FFFCF9;
            }
        }
    }
}

.swiper-button-wrapper {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
}