body {
  font-family: "NeueMachina";
  color: #fff;

  @include media("tablet-1000") {
    overflow: auto;
  }
}

html {
  @include media("tablet-1000") {
    overflow: auto;
  }
}

.section-main {
  padding-left: 71px;
  padding-top: toVh(171);
  padding-right: 70px;
  padding-bottom: 50px;
  height: 100%;


  @include media("tablet-1200", true) {
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 25px;
    padding-top: 100px;
  }

  @include media("mobile-620", true) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
    padding-top: 72px;
  }
}

.container {
  &-main {
    width: 100%;
    margin: 0 auto;
    height: auto;
    transform: translateY(300px);
    opacity: 0;
    transition: transform .6s ease, opacity .6s ease;
    transition-delay: .6s;
  }

  box-sizing: border-box;
  width: 95%;
  max-width: 1530px;
  margin: 0 auto;

  &-fluid {
    max-width: calc(100% - 200px);
    margin: auto;
  }

  @include media("tablet") {
    width: 95%;
    max-width: 1530px;
  }

  @include media("tablet-1200") {
    width: 738px;
    max-width: 100%;
  }

  @include media("mobile") {
    width: 100%;
    padding: 0 15px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #99e836;
  border-radius: 8px;
  border: 1px solid #000;
}