.about {
  &__row {
    width: 100%;

    @include media("mobile", false) {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 60% calc(40% - 40px);
      column-gap: 40px;
      grid-template-areas:
        "title title"
        "work-info work-info"
        "img-wrapper skills";
    }

    @include media("tablet-1200", false) {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: calc(30% - 40px) calc(50% - 40px) 20%;
      column-gap: 40px;
      grid-template-areas:
        "title img-wrapper work-info"
        "skills img-wrapper work-info";
    }

    @include media("tablet", false) {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: calc(40% - 40px) calc(40% - 40px) 20%;
      column-gap: 40px;
      grid-template-areas:
        "title img-wrapper work-info"
        "skills img-wrapper work-info";
    }
  }

  &__title {
    grid-area: title;

    @include media("tablet-1200", true) {
      margin-bottom: 60px;
    }
  }

  &__skills {
    grid-area: skills;
    display: grid;
    margin-top: 20px;
    grid-template-rows: 1fr auto;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    column-gap: 40px;
    grid-template-areas:
      "title-one title-two"
      "desc-one desc-two";


    @include media("tablet-1200", true) {}

    @include media("tablet", true) {
      display: grid;
      grid-template-columns: 100%;
      column-gap: 0px;
      grid-template-rows: auto;
      grid-template-areas:
        "title-one"
        "desc-one"
        "title-two"
        "desc-two";
    }

    @include media("mobile", true) {
      display: grid;
      grid-template-columns: calc(50% - 15px) calc(50% - 15px);
      column-gap: 30px;
      grid-template-rows: auto;
      grid-template-areas:
        "title-one title-two"
        "desc-one desc-two";
    }

    @include media("520", true) {
      display: grid;
      grid-template-columns: 50%;
      column-gap: 30px;
      grid-template-rows: auto;
      grid-template-areas:
        "title-one title-two"
        "desc-one desc-one"
        "desc-two desc-two";
    }
  }

  &__skill-title {
    display: flex;
    align-items: center;
    margin-bottom: 84px;

    @include media("tablet", true) {
      margin-bottom: 30px;
    }

    div {
      margin-right: 10px;
    }

    &-one {
      grid-area: title-one;
    }

    &-two {
      grid-area: title-two;
    }
  }

  &__skill-desc {
    @include media("mobile-450") {
      font-size: 12px;
    }

    &-one {
      grid-area: desc-one;

      @include media("tablet", true) {
        margin-bottom: 40px;
      }
    }

    &-two {
      grid-area: desc-two;

      @include media("tablet", true) {
        margin-bottom: 40px;
      }
    }
  }

  &__skill-counter {
    @include media(992) {
      font-size: 50px;
    }

    @include media("mobile-450") {
      font-size: 40px;
    }
  }

  &__skill-name {
    @include media(992) {
      font-size: 14px;
    }

    @include media("mobile-450") {
      font-size: 12px;
    }
  }

  &__img-wrapper {
    grid-area: img-wrapper;
    position: relative;
    width: 100%;
    height: toVh(910);
    margin-top: -70px;

    @include media("tablet-1200", true) {
      margin-top: 0;
      height: toVh(740);
    }

    @include media("mobile", true) {
      margin-bottom: 60px;
      height: toVh(900);
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__work-info {
    margin-top: 240px;
    text-transform: none;
    grid-area: work-info;

    @include media("tablet-1200", true) {
      width: 60%;
      margin-top: 0;
      margin-bottom: 40px;
    }

    @include media("mobile", true) {
      width: 100%;
    }
  }
}