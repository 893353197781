.home {
  height: 100vh;

  @include media(992) {
    min-height: 960px;
  }

  &-container {
    position: relative;
    display: flex;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;

    @include media(1024) {
      flex-direction: column;
    }
  }

  &-image {
    position: relative;
    z-index: 10;
    flex-shrink: 0;
    width: 758px;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity .6s ease;
    transition-delay: .6s;

    &--mobile {
      display: none;
      transform: scaleX(-1);
    }

    @include media(1900) {
      width: 40%;
    }

    @include media(1024) {
      margin-top: 180px;
      width: 100%;
      height: 50%;
      min-height: 430px;

      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }

    @include media(992) {
      margin-top: 127px;
      height: 430px;
    }

    @include media("mobile-450") {
      margin-top: 111px;

      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }
    }
  }

  &-author {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 201px 64px 52px 40px;

    @include media(1921, false) {
      width: 1162px;
      margin-left: auto;
    }

    @include media("tablet") {
      padding: 9% 2% 3% 40px;
    }

    @include media(992) {
      flex-direction: row;
      height: 100%;
      padding: 40px 40px 52px 48px;
    }

    @include media(767) {
      height: 42vh;
    }

    @include media("mobile-450") {
      flex-direction: column;
      height: auto;
      padding: 35px 10px 146px;
    }

    &__name {
      order: 1;
      max-width: 400px;
      opacity: 0;
      transition: opacity .6s ease;
      transition-delay: .6s;

      @include media("tablet") {
        max-width: 300px;
        font-size: 100px;
      }

      @include media("tablet-1200") {
        max-width: 300px;
        font-size: 90px;
      }

      @include media(992) {
        margin-top: -8px;
        order: 0;
        font-size: 70px;
        line-height: 110%;
      }

      @include media("mobile-620") {
        margin-top: -4px;
        max-width: 200px;
        font-size: 60px;
        line-height: 90%;
      }

      @include media("mobile-450") {
        margin: 0 0 28px;
      }
    }

    &__description {
      margin-bottom: auto;
      margin-left: auto;
      max-width: 324px;

      @include media(992) {
        max-width: 206px;
      }

      @include media("mobile-450") {
        margin: 0;
        max-width: 132px;
      }
    }

    &__button {
      position: absolute;
      right: 290px;
      bottom: 282px;
      width: 200px;
      height: 200px;
      overflow: hidden;

      @include media(1900) {
        right: 5%;
        bottom: 10%;
      }

      @include media("tablet-1200") {
        width: 150px;
        height: 150px;
      }

      @include media(992) {
        right: 95px;
        bottom: 90px;
      }

      @include media("mobile-620") {
        right: 25px;
        bottom: 60px;
      }

      @include media("mobile-450") {
        width: 120px;
        height: 120px;
      }
    }
  }

  &-ticker {
    position: absolute;
    top: 391px;
    right: 0;
    width: 90.5%;
    overflow: hidden;

    color: $main-green;

    @include media("tablet") {
      top: 35%;
      width: 96.5%;
    }

    @include media(1366) {
      width: 102%;
    }

    @include media(1024) {
      top: 75px;
    }

    @include media(992) {
      top: 90px;
    }

    @include media("mobile-450") {
      top: 74px;
    }

    &__wrapper {
      display: flex;
      width: 200%;
      animation: moveTicker 10s linear infinite;

      @include media(1024) {
        width: 800%;
      }

      @include media(992) {
        width: 380%;
      }

      @include media(768) {
        width: 420%;
      }

      @include media(540) {
        width: 400%;
      }

      @include media("mobile-450") {
        width: 390%;
      }

      @include media(375) {
        width: 865%;
      }

      @include media(360) {
        width: 900%;
      }

      @include media(320) {
        width: 848%;
      }
    }

    &__text {
      white-space: nowrap;
      width: 100%;

      @include media(1024) {
        width: 400%;
      }

      @include media(992) {
        width: 190%;
      }

      @include media(540) {
        width: 210%;
      }

      @include media("mobile-450") {
        width: 195%;
      }

      @include media(375) {
        width: 432.5%;
      }

      @include media(360) {
        width: 450%;
      }

      @include media(320) {
        width: 424%;
      }
    }
  }

  &-button {
    &__item {
      width: 100%;
      height: 100%;
      background: url("../img/main/home/button.svg") no-repeat;
      background-size: contain;
      @include run-circle;
    }
  }

  &__image {
    @include media(1921, false) {
      position: absolute;
      margin-left: -87.5px;
      width: 845.5px;
    }

    @include media(2101, false) {
      position: absolute;
      margin-left: -197.5px;
      width: 955.5px;
    }

    @include media(2321, false) {
      margin-left: -317.5px;
      width: 1075.5px;
    }
  }
}

@keyframes moveTicker {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}