.experience {
    &__title {
        margin-bottom: toVh(50);
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @include media("tablet-1200", true) {
            flex-direction: column-reverse;
        }
    }

    &__resume {
        width: 550/1780 * 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin-bottom: 20px;

        @include media("tablet-1200", true) {
            width: 60%;
            margin-bottom: 40px;
        }

        @include media("mobile", true) {
            width: 80%;
            margin-bottom: 40px;
        }

        @include media("mobile-620", true) {
            width: 100%;
            margin-bottom: 40px;
        }
    }

    &__resume-text {
        color: $light-grey;
        margin-bottom: toVh(70);

        @include media("mobile-620", true) {
            width: 100%;
            margin-bottom: 40px;
        }

        @include media("mobile-450") {
            font-size: 12px;
        }
    }

    &__resume-button {
        min-width: 323px;

        @include media("mobile", true) {
            min-width: 299px;
        }

        @include media("mobile-450", true) {
            min-width: 207px;
        }
    }

    &__history {
        width: 1052/1780 * 100%;
        cursor: none;

        @include media("tablet-1200", true) {
            width: 100%;
        }

        @include media("mobile", true) {
            margin-bottom: 40px;
        }

        &:hover {
            .experience__card:not(:hover) {
                color: $dark-grey-2;
                border-top-color: $dark-grey-2;
            }
        }
    }

    &__card {
        position: relative;
        z-index: 1;
        display: block;
        padding: 50px 0;
        color: $main-white;
        cursor: none;
        transition: z-index 0.3s, color 0.3s linear, border-color 0.3s linear;

        @include media("tablet-1000", true) {
            padding: 30px 0;
        }

        @include media("mobile-620", true) {
            padding: 20px 0;
        }

        &:hover {
            z-index: 2;
        }

        &:not(:first-child) {
            margin-top: -1px;
            border-top: 1px solid $light-grey;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $light-grey;
        }
    }

    &__card-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__position {
        @include media("mobile-620", true) {
            width: 50%;
        }
    }

    &__company {
        align-items: center;

        @include media("mobile") {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
        }

        @include media("mobile-450") {
            font-size: 12px;
        }

        @include media("mobile-450") {
            font-size: 12px;
        }

        @include media("mobile-450") {
            font-size: 12px;
        }
    }

    &__date {
        @include media("mobile-450") {
            font-size: 12px;
        }
    }
}