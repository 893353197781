.works {
  &__container {
    position: relative;
  }

  &__slide {
    &-xs {
      @include media("tablet-1000", true) {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 70px;

    @include media("tablet", true) {
      margin-bottom: 45px;
    }
  }

  &__row {
    padding-bottom: 50px;
  }

  &__swiper-container {
    cursor: url("../img/cursor-slider.png") 62 62, auto;

    margin-right: -70px;
    overflow: hidden;

    @include media("tablet-1200", true) {
      margin-right: -45px;
    }

    @include media("mobile-620", true) {
      margin-right: -10px;
    }

  }


  &__swiper-slide {}

  &__card {
    display: block;
    height: 630px;
    position: relative;

    @include media("tablet-1000", true) {
      height: 550px;
    }

    @include media("mobile-450", true) {
      width: 200px;
      height: 400px;
    }
  }

  &__img {}

  &__text {
    position: absolute;
    left: 30px;
    bottom: 20px;
    text-transform: none;

    @include media("mobile") {
      font-size: 24px;
      line-height: 120%;
    }

    @include media("mobile-450") {
      font-size: 18px;
    }
  }
}

.swiper-slide {
  width: auto;
}

.swiper-button-prev2 {
  margin-right: 10px;
  cursor: pointer;

  svg {
    circle {
      transition: stroke .3s ease;
    }

    path {
      transition: stroke .3s ease;
    }
  }

  &:hover {
    svg {
      circle {
        stroke: #FFFCF9;
      }

      path {
        stroke: #FFFCF9;
      }
    }
  }
}

.swiper-button-next2 {
  cursor: pointer;

  svg {
    circle {
      transition: stroke .3s ease;
    }

    path {
      transition: stroke .3s ease;
    }
  }

  &:hover {
    svg {
      circle {
        stroke: #FFFCF9;
      }

      path {
        stroke: #FFFCF9;
      }
    }
  }

}

.swiper-button-wrapper {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;

  @include media("tablet-1000", true) {
    display: none !important;
  }
}