.neue {
  font-family: "NeueMachina", sans-serif;
  font-style: normal;

  &-headline1 {
    font-weight: 500;
    font-size: 140px;
    line-height: 90%;
    text-transform: uppercase;

    @include media("1920", true) {
      font-size: 112px;
    }

    @include media("tablet", true) {
      font-size: 96px;
    }

    @include media("1350", true) {
      font-size: 70px;
    }

    @include media("mobile", true) {
      font-size: 56px;
    }

    @include media("620", true) {
      font-size: 42px;
    }

    @include media("mobile-450", true) {
      font-size: 32px;
    }
  }

  &-headline2 {
    font-weight: 500;
    font-size: 70px;
    line-height: 110%;
    text-transform: uppercase;

    @include media("tablet", true) {
      font-size: 56px;
    }

    @include media(992) {
      font-size: 22px;
    }

    &-green {
      color: $main-green;
      font-weight: 500;
      font-size: 70px;
      line-height: 110%;
      text-transform: uppercase;

      @include media("tablet", true) {
        font-size: 52px;
      }

      @include media("tablet-1000", true) {
        font-size: 50px;
      }

      @include media("mobile", true) {
        font-size: 36px;
      }

      @include media("mobile-450", true) {
        font-size: 22px;
      }
    }
  }

  &-headline3 {
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;

    @include media("tablet", true) {
      font-size: 32px;
    }

    @include media("tablet-1000", true) {
      font-size: 24px;
    }

    @include media("mobile-450", true) {
      font-size: 18px;
    }
  }

  &-text1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;

    @include media("tablet", true) {
      font-size: 22px;
    }

    @include media("tablet-1000", true) {
      font-size: 18px;
    }

    @include media("mobile", true) {
      font-size: 16px;
    }

    @include media("mobile-620", true) {
      font-size: 12px;
    }
  }

  &-text2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;

    @include media("mobile-1000", true) {
      font-size: 14px;
    }

    @include media("mobile-450", true) {
      font-size: 12px;
    }
  }

  &-link {
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    text-transform: uppercase;
    color: $main-green;

    @include animation;

    @include media(tablet-1000, false) {
      &:hover {
        color: $main-white;

        @include animation;
      }
    }
  }
}

.manrope {
  font-family: "Manrope", sans-serif;
  font-style: normal;

  &-text1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;

    @include media("tablet-1200", true) {
      font-size: 14px;
    }
  }

  &-text2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;

    @include media("tablet-1200", true) {
      font-size: 16px;
    }

    @include media("tablet-1000", true) {
      font-size: 14px;
    }

    @include media("mobile-450", true) {
      font-size: 11px;
    }
  }
}

.text {
  color: $light-grey;
}

.sfpro {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
}