.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  &__wrapper {
    padding: 35px 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media("tablet-1200", true) {
      padding: 20px 45px;
    }

    @include media(768, true) {
      flex-direction: row-reverse;
    }

    @include media("mobile-450") {
      padding: 12px 10px 10px 6px;
    }
  }

  &__logo {
    font-size: 24px;
    line-height: 120%;
    transform: rotate(-90deg);

    @include media(1440) {
      margin-left: auto;
      margin-right: 31%;
    }

    @include media(1400) {
      margin-left: auto;
      margin-right: 29%;
    }

    @include media(1350) {
      margin-left: auto;
      margin-right: 26%;
    }

    @include media(1200) {
      margin-left: auto;
      margin-right: 23%;
    }

    @include media(1024) {
      margin-left: auto;
      margin-right: auto;
    }

    @include media("mobile-450") {
      font-size: 18px;
    }
  }

  &__burger {
    width: 50px;
    height: 14px;
    position: relative;
    cursor: pointer;

    @include media("mobile-450") {
      width: 30px;
    }

    span {
      width: 100%;
      height: 100%;

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 2px;
        background-color: #fffcf9;

        @include media("mobile-450") {
          width: 30px;
        }
      }

      &:after {
        top: 0;
      }

      &::before {
        bottom: 0;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 120%;

    @include media(768, true) {
      display: none;
    }

    li {
      &:not(:last-child) {
        margin-right: 150px;
      }

      @include media(993, false) {
        @include animation;

        &:hover {
          color: $main-green;

          @include animation;
        }
      }
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    background: #99e836;
    width: 758px;
    height: 100vh;
    z-index: 100;
    font-size: 40px;
    line-height: 120%;
    transition: 0.3s;
    opacity: 0;
    transform: translateX(-100%);
    overflow-y: auto;

    @include media(768, true) {
      width: 369px;
      left: auto;
      right: 0;
      transform: translateX(100%);
    }

    @include media(413, true) {
      width: 100vw;
    }

    &-list {
      opacity: 0;
      transition: opacity 0.5s ease;
      transition-delay: 0.2s;
    }

    &.active {
      opacity: 1;
      transform: translateX(0);

      .header__menu-list {
        opacity: 1;
      }
    }

    &-content {
      position: relative;
      padding-top: 178px;
      padding-left: 250px;
      padding-bottom: 136px;

      @include media(768, true) {
        padding-top: 99px;
        padding-left: 118px;
        padding-bottom: 90px;
        font-size: 24px;
        line-height: 120%;
      }

      @include media("mobile-620") {
        padding-top: 68px;
        padding-left: 60px;
        padding-bottom: 60px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
      }
    }

    &-exit {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 30px;
      left: 67px;
      transition: 0.3s ease;
      cursor: pointer;
      z-index: 3;

      @include media(768, true) {
        left: auto;
        right: 45px;
      }

      @include media(767, true) {
        right: 16px;
        top: 8px;
        width: 30px;
        height: 30px;
      }

      &:hover {
        opacity: 0.7;
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 20px;
        display: block;
        height: 40px;
        width: 2px;
        background-color: #0d0d0c;

        @include media(768, true) {
          height: 30px;
        }
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }

    &-item {
      &.active {
        & a::after {
          right: 0;
          background-color: $main-black;
        }
      }

      &:not(:first-child) {
        margin-top: 31px;

        @include media(768) {
          margin-top: 21px;
        }

        @include media("mobile-620") {
          margin-top: 11px;
        }
      }
    }

    &-link {
      position: relative;
      padding-bottom: 11px;

      color: #0d0d0c;
      transition: transform 0.3s ease;
      display: inline-block;

      @include media("mobile-620") {
        padding-bottom: 10px;
      }

      &::after {
        content: "";
        position: absolute;
        transition: 0.3s ease;
        bottom: 0;
        left: 0;
        height: 2px;

        background-color: transparent;
      }
      &:hover {
        transform: translate(40px, 0);
      }
    }

    &-logo {
      display: none;
      position: absolute;
      top: 12px;
      left: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      text-transform: uppercase;
      color: $main-black;
      transform: rotate(-90deg);

      @include media(767) {
        display: block;
      }
    }
  }
}
