@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Medium.woff2") format("woff2"),
    url("../fonts/Manrope-Medium.woff") format("woff"),
    url("../fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Regular.woff2") format("woff2"),
    url("../fonts/Manrope-Regular.woff") format("woff"),
    url("../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMachina";
  src: url("../fonts/NeueMachina-Regular.woff2") format("woff2"),
    url("../fonts/NeueMachina-Regular.woff") format("woff"),
    url("../fonts/NeueMachina-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMachina";
  src: url("../fonts/NeueMachina-Medium.woff2") format("woff2"),
    url("../fonts/NeueMachina-Medium.woff") format("woff"),
    url("../fonts/NeueMachina-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMachina";
  src: url("../fonts/NeueMachina-Medium.woff2") format("woff2"),
    url("../fonts/NeueMachina-Medium.woff") format("woff"),
    url("../fonts/NeueMachina-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
