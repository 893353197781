.button-primary {
  color: $main-black;
  background: $main-green;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 28px;
  cursor: pointer;
  transition: background 0.3s linear;
  max-width: 244px;
  min-width: 244px;

  @include media('tablet-1000', true) {
    max-width: 227px;
    min-width: 227px;
    padding: 23px 28px;
  }

  @include media('mobile-450', true) {
    max-width: 164px;
    min-width: 164px;
    padding: 23px 28px;
  }

  img {
    @include media('tablet-1000', true) {
      width: 28px;
      height: 16px;
    }

    @include media('mobile-450', true) {
      width: 18px;
      height: 9px;
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    text-transform: uppercase;
    margin-right: 10px;

    @include media('tablet-1000', true) {
      font-size: 20px;
    }

    @include media('mobile', true) {
      font-size: 18px;
    }

    @include media('mobile-450', true) {
      font-size: 12px;
    }
  }

  &:hover {
    background: $main-white;
  }
}