.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 1000000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 50%);
}

.main-circle {
    width: 150px;
    height: 150px;
    border: 4px solid #99E836;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 2s infinite;
}

.main-circle:before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    border: 4px solid transparent;
    border-right: 4px solid #99E836;
    transform: rotate(40deg);
    border-radius: 50%;
}

.green-circle {
    animation: rotate 2s infinite .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border: 4px solid #99E836;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    transform: rotate(-20deg);
    border-radius: 50%;
    position: relative;
}

.green-circle:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    border: 4px solid transparent;
    border-right: 4px solid #99E836;
    transform: rotate(60deg);
}

.brown-circle {
    animation: rotate 2s infinite .6s;
    width: 80px;
    height: 80px;
    border: 4px solid #99E836;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    transform: rotate(-20deg);
    border-radius: 50%;
}

@keyframes rotate {
    from {}

    to {
        transform: rotate(360deg);
    }
}