.container {
  max-width: calc(100% - 200px);
  margin: auto;
}

.line {
  height: 2px;
  background: #787a7c;
  width: 100%;
}

#pp-nav {
  display: none;
}

body {
  background: $main-black;
  color: $main-white;
}

.section {
  background-color: #000;
  height: 100%;

  &.active {

    .section-main {
      .container-main {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}